<template>
  <div class="d-flex flex-column-fluid">
    <!--begin::Container-->
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <!--begin::Card-->
          <div class="card card-custom gutter-b example example-compact">
            <div class="card-header">
              <h3 v-if="$route.meta.mode !== 'update'" class="card-title">
                Ajouter un rôle
              </h3>
              <h3 v-if="$route.meta.mode === 'update'" class="card-title">
                Modifier un rôle
              </h3>
            </div>
            <!--begin::Form-->
            <form class="form" @submit.prevent="save">
              <div class="card-body">
                <div class="form-group row">
                  <div class="col-lg-6">
                    <label>Rôle:</label>
                    <input
                      type="text"
                      :value="form.name"
                      disabled
                      class="form-control"
                    />
                  </div>
                  <div class="col-lg-6">
                    <label for="">Permissions</label>
                    <multiselect
                      v-model="form.permissions"
                      tag-placeholder="Ajouter un permission"
                      placeholder="Selectionner tag"
                      label="name"
                      track-by="id"
                      :options="permissions"
                      :multiple="true"
                      :taggable="true"
                    >
                    </multiselect>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="row">
                  <div class="col-lg-12 text-right">
                    <button type="submit" class="btn btn-primary mr-2">
                      Enregistrer
                    </button>
                    <button type="reset" class="btn btn-secondary">
                      Annuler
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <!--end::Form-->
          </div>
          <!--end::Card-->
        </div>
      </div>
    </div>
    <!--end::Container-->
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";

export default {
  name: "AddPermissionRole",
  components: { Multiselect },
  data() {
    return {
      source: "role",
      initialize: "",
      resource: "role",
      method: "post",
      form: {
        name: "",
        role_id: ""
      },
      store: "role-permission",
      role: [],
      permissions: [],
      success: false,
      has_error: false,
      error: "",
      errors: {}
    };
  },
  beforeMount() {
    if (this.$route.meta.mode === "update") {
      this.initialize = "attach-role/" + this.$route.params.id + "/edit";
    }
    this.fetchData();
  },
  methods: {
    fetchData() {
      let vm = this;
      ApiService.get(this.initialize).then(function(response) {
        console.log(response.data);
        vm.form.name = response.data.role.name;
        vm.form.role_id = response.data.role.id;
        vm.permissions = response.data.permissions;
      });
    },
    save() {
      let vm = this;
      console.log(vm.form);
      ApiService[this.method](this.store, this.form)
        .then(function(response) {
          if (response.data.success) {
            if (vm.$route.meta.mode === "create") {
              Swal.fire({
                title: "Réussie",
                text: "Permissions attachées avec succès.",
                icon: "success",
                showConfirmButton: false,
                timer: 2000,
                showClass: {
                  popup: "animate__animated animate__fadeInDown"
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp"
                }
              });
              vm.$router.push(vm.$route.meta.back);
            } else {
              Swal.fire({
                title: "Réussie",
                text: "ACL a été modifié avec succès.",
                icon: "success",
                showConfirmButton: false,
                timer: 2000,
                showClass: {
                  popup: "animate__animated animate__fadeInDown"
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp"
                }
              });
              vm.$router.push(vm.$route.meta.back);
            }
            vm.$router.push(vm.$route.meta.back);
          }
        })
        .catch(function(error) {
          vm.errors = error.response.data.errors;
        });
    }
  }
};
</script>

<style scoped></style>
